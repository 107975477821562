<template>
  <div style="height: 100%">
    <bread></bread>
    <div class="center-content">
      <div class="iq-card-body">
        <div class="table-top flex-space-between">
          <el-page-header @back="goBack" :content="content"> </el-page-header>
          <div>
            <el-button type="primary" size="small" @click="goBack" plain>取消</el-button>
            <el-button type="primary" size="small" @click="saveData('form')" v-if="prescriptionType == 2">保存</el-button>
          </div>
        </div>
        <div class="table-line"></div>
        <div class="table-content">
          <el-form ref="form" :model="form" label-width="90px" :rules="rules" style="width: 90%">
            <el-form-item label="处方名称:" prop="prescriptionName">
              <el-input v-model="form.prescriptionName" :disabled="prescriptionType != 2"></el-input>
            </el-form-item>
            <el-form-item label="输液处方:" :rules="{ required: true, message: '不能为空', trigger: 'blur' }">
              <div class="my-table">
                <div v-for="(group, index) in form.mzPrescriptionGroup" :key="index">
                  <div style="padding: 0 10px">
                    <span style="margin-right: 10px">组{{ index + 1 }}</span>
                    <span class="danger optionBtn" @click="handleDelGroup(index)" v-if="form.mzPrescriptionGroup.length > 1">删除</span>
                  </div>
                  <el-row class="bottom-row">
                    <el-col :span="4">
                      <el-form-item label="用法" :prop="`mzPrescriptionGroup[${index}].usage`" :rules="rules.usage">
                        <el-select v-model="group.usage" size="small" placeholder="用法" clearable :disabled="prescriptionType != 2">
                          <el-option :label="item" :value="item" v-for="item in usage"></el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>
                    <el-col :span="4">
                      <el-form-item label="用药频率">
                        <el-select v-model="group.frequency" size="small" placeholder="用药频率" clearable :disabled="prescriptionType != 2">
                          <el-option :label="item" :value="item" v-for="item in frequency"></el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-row>
                        <el-col :span="12">
                          <el-form-item label="滴速">
                            <el-input-number controls-position="right" size="small" v-model="group.drippingSpeed" :precision="0" :step="1" :disabled="prescriptionType != 2"></el-input-number>
                          </el-form-item>
                        </el-col>
                        <el-col :span="12">
                          <el-form-item label="滴速单位">
                            <el-select v-model="group.drippingSpeedUnit" size="small" placeholder="滴速单位" clearable :disabled="prescriptionType != 2">
                              <el-option :label="item" :value="item" v-for="item in drippingSpeedUnit"></el-option>
                            </el-select>
                          </el-form-item>
                        </el-col>
                      </el-row>
                    </el-col>
                    <el-col :span="4">
                      <el-form-item label="起始日期">
                        <el-date-picker v-model="group.startTime" :picker-options="pickerOptions0" type="date" size="small" placeholder="选择日期" style="width: 100%" :disabled="prescriptionType != 2"> </el-date-picker>
                      </el-form-item>
                    </el-col>
                    <el-col :span="4">
                      <el-form-item label="连续天数">
                        <el-input-number controls-position="right" size="small" v-model="group.days" :precision="0" :step="1" :disabled="prescriptionType != 2"></el-input-number>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-table :data="group.mzPrescriptionDetailSY" style="width: 100%">
                    <el-table-column prop="name" label="药品名称" width="260">
                      <template slot-scope="scope">
                        <el-form-item label="" :prop="`mzPrescriptionGroup[${index}].mzPrescriptionDetailSY[${scope.$index}].drugOrgId`" :rules="rules.drugOrgId">
                          <el-select class="" size="small" v-model="scope.row.drugOrgId" filterable remote clearable placeholder="请输入关键词" :remote-method="remoteMethod" :loading="isLoading" @change="handleSelect($event, scope.row)" :disabled="prescriptionType != 2">
                            <el-option v-for="item in options" :key="item.id" :label="`${item.mzDrug.drugName}${item.mzDrug.drugType == '' ? '' : item.mzDrug.drugType == 'T' ? '(统)' : item.mzDrug.drugType == 'X' ? '(选)' : '(精选)'}(${item.specification}/${item.specificationUnit}${item.productFactory ? ' - ' + item.productFactory : ''})`" :value="item.id"> </el-option>
                          </el-select>
                        </el-form-item>
                      </template>
                    </el-table-column>
                    <el-table-column prop="st" label="皮试">
                      <template slot-scope="scope">
                        <el-form-item label="">
                          <el-input v-model="scope.row.st" size="small" placeholder="皮试" clearable :disabled="prescriptionType != 2"></el-input>
                        </el-form-item>
                      </template>
                    </el-table-column>
                    <el-table-column prop="dosage" label="单次剂量">
                      <template slot-scope="scope">
                        <el-form-item label="" :prop="`mzPrescriptionGroup[${index}].mzPrescriptionDetailSY[${scope.$index}].dosage`" :rules="rules.dosage">
                          <el-input-number controls-position="right" size="small" v-model="scope.row.dosage" :precision="0" :step="1" :min="1" :disabled="prescriptionType != 2"></el-input-number>
                        </el-form-item>
                      </template>
                    </el-table-column>
                    <el-table-column prop="dosageUnit" label="剂量单位" width="120">
                      <template slot-scope="scope">
                        <el-form-item label="" :prop="`mzPrescriptionGroup[${index}].mzPrescriptionDetailSY[${scope.$index}].dosageUnit`" :rules="rules.dosageUnit">
                          <el-select size="small" v-model="scope.row.dosageUnit" placeholder="单位" :disabled="prescriptionType != 2">
                            <el-option :label="item" :value="item" v-for="item in dosageUnit"></el-option>
                          </el-select>
                        </el-form-item>
                      </template>
                    </el-table-column>
                    <el-table-column prop="defaultDosage" label="开药量">
                      <template slot-scope="scope">
                        <el-form-item label="" :prop="`mzPrescriptionGroup[${index}].mzPrescriptionDetailSY[${scope.$index}].defaultDosage`" :rules="rules.defaultDosage">
                          <el-input-number controls-position="right" size="small" v-model="scope.row.defaultDosage" :precision="0" :step="1" :min="1" :disabled="prescriptionType != 2"></el-input-number>
                        </el-form-item>
                      </template>
                    </el-table-column>
                    <el-table-column prop="defaultDosageUnit" label="开药量单位" width="120">
                      <template slot-scope="scope">
                        <el-form-item label="" :prop="`mzPrescriptionGroup[${index}].mzPrescriptionDetailSY[${scope.$index}].defaultDosageUnit`" :rules="rules.defaultDosageUnit">
                          <el-select size="small" v-model="scope.row.defaultDosageUnit" placeholder="单位" disabled>
                            <el-option :label="item" :value="item" v-for="item in dosageUnit"></el-option>
                          </el-select>
                        </el-form-item>
                      </template>
                    </el-table-column>
                    <!-- <el-table-column prop="tradingPrice" label="价格"></el-table-column> -->
                    <el-table-column align="center" width="100px" v-if="prescriptionType == 2">
                      <template slot-scope="scope">
                        <el-button type="danger" size="mini" plain @click="handleDelete(group, scope.row, scope.$index)">
                          <i class="el-icon-delete" aria-hidden="true"></i>
                        </el-button>
                      </template>
                    </el-table-column>
                  </el-table>
                  <div style="padding: 0 10px" v-if="prescriptionType == 2">
                    <span class="primary optionBtn" @click="handleAdd(index)">添加药品</span>
                    <span class="primary optionBtn" @click="handleGroup(group)">添加组</span>
                    <span class="warning optionBtn" @click="handleReset(group)">重置</span>
                  </div>
                </div>
              </div>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Outpatient } from '@/components/DrugDomain/Outpatient'
export default {
  data() {
    var outpatient = new Outpatient(this.TokenClient, this.Services.Drug)
    return {
      OutpatientDomain: outpatient,
      id: this.$route.query.id,
      form: {
        prescriptionName: '',
        type: 2, //0方剂（饮片）处方 1 成药处方 2 输液处方
        mzPrescriptionDetail: [],
        mzPrescriptionGroup: [
          {
            id: 0,
            usage: null,
            frequency: null,
            drippingSpeed: undefined,
            drippingSpeedUnit: null,
            startTime: new Date(),
            days: undefined,
            mzPrescriptionDetailSY: [
              {
                drugOrgId: '',
                drugOrgName: '',
                st: null,
                dosage: undefined,
                dosageUnit: null,
                defaultDosage: undefined,
                defaultDosageUnit: null,
                tradingPrice: null,
              },
            ],
          },
        ],
      },
      pickerOptions0: {
        disabledDate(time) {
          return time.getTime() < Date.now()
        },
      },
      options: [],
      dosageUnit: ['片', '粒', '支', '袋', '枚', '瓶', '滴', '适量', 'ug', 'mg', 'g', 'ul', 'ml', 'IU'],
      usage: ['静脉注射', '静脉滴注', '肌内注射', '皮下注射', '皮内注射', '静脉推注', '体腔内注射', '动脉注射', '心内注射'],
      frequency: ['1日1次(qd)', '1日2次(bid)', '1日3次(tid)', '隔日1次(qod)', '必要时(prn)', '1日4次(qid)', '1周1次(qwd)', '隔周1次(qow)', '隔天1次(qod)', '每晚1次(qn)', '立即(st)'],
      drippingSpeedUnit: ['g/分钟', 'ml/分钟', 'ml/小时', '分钟/次', 'ug/分钟', '滴/分钟'],
      remark: ['先煎', '后下', '包煎', '另煎', '烊化', '冲服', '捣碎'],
      rules: {
        prescriptionName: [{ required: true, message: '请输入处方名称', trigger: 'blur' }],
        drugOrgId: [{ required: true, message: '请输入药品名称', trigger: 'blur' }],
        st: [{ required: true, message: '请输皮试', trigger: 'blur' }],
        usage: [{ required: true, message: '请选择用法', trigger: 'change' }],
        frequency: [{ required: true, message: '请选择用药频率', trigger: 'change' }],
        drippingSpeed: [{ required: true, message: '请输入滴速', trigger: 'blur' }],
        drippingSpeedUnit: [{ required: true, message: '请选择滴速单位', trigger: 'change' }],
        startTime: [{ required: true, message: '请选择起始日期', trigger: 'change' }],
        days: [{ required: true, message: '请输入天数', trigger: 'blur' }],
        dosage: [{ required: true, message: '请输入剂数', trigger: 'blur' }],
        dosageUnit: [{ required: true, message: '请选择剂量单位', trigger: 'change' }],
        defaultDosage: [{ required: true, message: '请输入开药量', trigger: 'blur' }],
        defaultDosageUnit: [{ required: true, message: '请选择开药量单位', trigger: 'change' }],
      },
      prescriptionType: 2, //1个人 2机构 3总后台
      content: '',
      isLoading: false,
    }
  },
  mounted() {
    if (this.id) {
      this.getPrescriptionInfo()
    } else {
      this.content = '新增输液处方'
    }
  },
  methods: {
    remoteMethod(queryString) {
      var _this = this
      if (queryString !== '') {
        this.isLoading = true
        setTimeout(() => {
          this.isLoading = false
          _this.OutpatientDomain.DrugByOrgIn(
            queryString,
            function (res) {
              _this.options = res.data
            },
            function (error) {
              console.log('药品列表请求失败!请刷新重试:', error)
            }
          )
        }, 200)
      } else {
        this.options = []
      }
    },
    handleSelect(e, map) {
      let item = this.options.filter((i) => i.id == e)[0]
      if (item) {
        map.drugOrgName = item.mzDrug.drugName
        map.tradingPrice = item.tradingPrice
        map.st = null
        map.dosage = item.dosage
        map.dosageUnit = item.dosageUnit
        map.defaultDosage = item.defaultDosage
        map.defaultDosageUnit = item.defaultDosageUnit
      }
    },
    saveData(formName) {
      var _this = this
      _this.$refs[formName].validate((valid) => {
        if (valid) {
          if (_this.form.id) {
            _this.OutpatientDomain.EditMZPrecription(
              _this.form,
              function (data) {
                _this.$message({
                  message: '修改成功',
                  type: 'success',
                })
                _this.$router.go(-1)
              },
              function (error) {
                console.log(error)
              }
            )
          } else {
            _this.OutpatientDomain.AddMZPrecription(
              _this.form,
              function (data) {
                _this.$message({
                  message: '添加成功',
                  type: 'success',
                })
                _this.$router.go(-1)
              },
              function (error) {
                console.log(error)
              }
            )
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    getPrescriptionInfo() {
      var _this = this
      _this.OutpatientDomain.PrecriptionDetail(
        _this.id,
        function (res) {
          _this.prescriptionType = res.data.prescriptionType
          if (_this.prescriptionType == 1) {
            _this.content = '输液处方详情'
          } else {
            _this.content = '编辑输液处方'
          }
          _this.form.id = res.data.id
          _this.form.prescriptionName = res.data.prescriptionName
          _this.form.type = res.data.type
          _this.form.mzPrescriptionGroup = []
          res.data.mzPrescriptionGroup.forEach((item) => {
            let row = {
              id: 0,
              usage: item.usage,
              frequency: item.frequency,
              drippingSpeed: item.drippingSpeed ? item.drippingSpeed : undefined,
              drippingSpeedUnit: item.drippingSpeedUnit,
              startTime: item.startTime,
              days: item.days ? item.days : undefined,
              mzPrescriptionDetailSY: [],
            }
            item.mzPrescriptionDetailSY.forEach((i) => {
              let mzPrescriptionDetailSY = {
                drugOrgId: i.id,
                drugOrgName: i.drugOrgName,
                st: i.st,
                dosage: i.dosage,
                dosageUnit: i.dosageUnit,
                defaultDosage: i.defaultDosage,
                defaultDosageUnit: i.defaultDosageUnit,
                tradingPrice: i.tradingPrice,
              }
              row.mzPrescriptionDetailSY.push(mzPrescriptionDetailSY)
            })
            _this.form.mzPrescriptionGroup.push(row)
          })
        },
        function (error) {
          console.log(error)
        }
      )
    },
    handleReset(item) {
      let row = {
        drugOrgId: '',
        drugOrgName: '',
        st: null,
        dosage: undefined,
        dosageUnit: null,
        defaultDosage: undefined,
        defaultDosageUnit: null,
        tradingPrice: null,
      }
      item.mzPrescriptionDetailSY = []
      item.mzPrescriptionDetailSY.push(row)
    },
    handleGroup(item) {
      let row = {
        id: 0,
        usage: null,
        frequency: null,
        drippingSpeed: undefined,
        drippingSpeedUnit: null,
        startTime: new Date(),
        days: undefined,
        mzPrescriptionDetailSY: [
          {
            drugOrgId: '',
            drugOrgName: '',
            st: null,
            dosage: undefined,
            dosageUnit: null,
            defaultDosage: undefined,
            defaultDosageUnit: null,
            tradingPrice: null,
          },
        ],
      }
      this.form.mzPrescriptionGroup.push(row)
    },
    handleAdd(index) {
      let row = {
        drugOrgId: '',
        drugOrgName: '',
        st: null,
        dosage: undefined,
        dosageUnit: null,
        defaultDosage: undefined,
        defaultDosageUnit: null,
        tradingPrice: null,
      }
      this.options = []
      this.form.mzPrescriptionGroup[index].mzPrescriptionDetailSY.push(row)
    },
    handleDelGroup(index) {
      if (this.form.mzPrescriptionGroup.length == 1) {
        this.$message({
          message: '请至少保留一个组',
          type: 'warning',
        })
        return
      }
      this.$message({
        message: '删除成功',
        type: 'success',
      })
      this.form.mzPrescriptionGroup.splice(index, 1)
    },
    handleDelete(item, row, index) {
      if (item.mzPrescriptionDetailSY.length == 1) {
        this.$message({
          message: '请至少保留一行',
          type: 'warning',
        })
        return
      }
      this.$message({
        message: '删除成功',
        type: 'success',
      })
      item.mzPrescriptionDetailSY.splice(index, 1)
    },
    goBack() {
      this.$router.go(-1)
    },
  },
}
</script>
<style scoped lang="scss">
.iq-card-body {
  padding: 0 0 15px;

  .table-top {
    width: 96%;
    margin: 0 auto;
  }

  .table-line {
    width: 100%;
    height: 6px;
    background: #f5f6f7;
    margin: 0 0 15px;
  }

  .table-content {
    padding-left: 20px;
    height: calc(100% - 30px - 73px);
    overflow-y: auto;

    .title {
      background: #f4f5f7;
      height: 60px;
      line-height: 60px;
      padding: 0 15px;
      margin-bottom: 20px;
    }

    ::v-deep .el-table__row > td {
      /* 去除表格线 */
      border: none !important;
    }

    ::v-deep .el-table th.is-leaf {
      /* 去除上边框 */
      border: none !important;
    }

    ::v-deep .el-table::before {
      /* 去除下边框 */
      height: 0 !important;
    }

    ::v-deep .el-table .el-table__cell {
      padding: 0 !important;
    }

    .my-table {
      border: 1px solid #ebeef5;
      padding: 10px 5px;
    }
  }
}

::v-deep .bottom-row .el-form-item {
  margin-bottom: 22px !important;
  width: 100%;
}

::v-deep .el-input-number--small {
  width: auto !important;
}
</style>
